import { css } from '@emotion/react'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import { OptionItem } from '../components/contents/OptionItem'
import Single from '../components/contents/Single'

import { mq, rem } from '../styles/mixin.styles'
import { colors, space } from '../styles/variables.styles'

type Props = {
  location: {
    [key: string]: string
  }
}

const AboutPage = ({ location }: Props) => {
  return (
    <Single
      slug="about"
      subTitle="about us"
      readText="自動車業界に対する素材サプライヤーとしての本業で培ってきたリアル課題へのソリューションを提供いたします。"
      pathname={location.pathname}
    >
      <h2>ミッション</h2>
      <h3>パレットを入口に自動車部品メーカー様の調達DXへ貢献します。</h3>
      <p>
        パレット調達支援システムiPaSを入口に自動車部品メーカー様のDX課題を抽出し、その解決支援のネットワークやコミュニティを形成し提供いたします。
      </p>
      <h2>会社概要</h2>
      <dl css={dlStyles}>
        <div className="dl__item">
          <dt>会社名</dt>
          <dd>五十鈴ビジネスサポート株式会社</dd>
        </div>
        <div className="dl__item">
          <dt>住所</dt>
          <dd>〒100-0005 東京都千代田区丸の内2-2-1 岸本ビル</dd>
        </div>
        <div className="dl__item">
          <dt>代表取締役</dt>
          <dd>瀬戸 明宏</dd>
        </div>
        <div className="dl__item">
          <dt>設立</dt>
          <dd>2003年（平成15年）1月</dd>
        </div>
        <div className="dl__item">
          <dt>資本金</dt>
          <dd>2千万円</dd>
        </div>
        <div className="dl__item">
          <dt>事業内容</dt>
          <dd>各種素材及び鋼製商材の仕入・加工・販売に関する事務とその代行・請負
          <br />
          各種コンサルタント業務及びサービス提供
          </dd>
        </div>
      </dl>
      <h3 className="option-title">その他取扱製品</h3>
      <div css={optionItems}>
        <OptionItem
          title="スマートパレット"
          description="工場間で共有できる標準外装に専用内装や後付けオプションパーツの組み合わせを可能にすることで、調達ランニングコストを削減できるパレットシステムです。"
        >
          <StaticImage
            src="../images/option_1.png"
            width={266}
            height={198}
            alt="専用内装、オプションパーツのイメージ"
            placeholder="none"
          />
        </OptionItem>
        <OptionItem
          title="パレット流動稼働管理システム"
          description="工場構内のパレットに設置したRFIDタグをリアルタイムで検知し、構内パレットの稼働・流動・在庫状態をデジタルに可視化できるシステムです。"
        >
          <StaticImage
            src="../images/option_2.png"
            width={270}
            height={200}
            alt="パレット流動稼働管理システムによる可視化のイメージ"
            placeholder="none"
          />
        </OptionItem>
        <OptionItem
          title="構内物流デジタルソリューション"
          description="パレットに設置したRFIDタグを専用リーダーで読み込み、空台車になった車種・時間・個数などのデータをサテライト工場と共有できるシステムです。"
        >
          <StaticImage
            src="../images/option_3.png"
            width={257}
            height={191}
            alt="工場間での空台車データの共有イメージ"
            placeholder="none"
          />
        </OptionItem>
      </div>
    </Single>
  )
}

export default AboutPage

const dlStyles = css`
  .dl__item {
    border-bottom: ${rem(1)} solid ${colors.grayBorder.hex};
    padding: ${space.md} 0;
  }
  dt {
    font-weight: 700;
    margin-left: ${rem(10)};
    + dd {
      margin-top: ${space.md};
      margin-left: ${rem(10)};
    }
  }
  ${mq('lg')} {
    .dl__item {
      display: flex;
      gap: ${rem(64)};
    }
    dt {
      min-width: ${rem(120)};
      + dd {
        margin-top: 0;
      }
    }
  }
`

const optionItems = css`
  display: grid;
  row-gap: ${rem(30)};
`
