import { css } from '@emotion/react'
import React, { ReactNode } from 'react'
import { mq, rem } from '../../styles/mixin.styles'
import { colors, fontSize, space } from '../../styles/variables.styles'

type OptionItemProps = {
  title: string
  description?: string
  children?: ReactNode
}

/**
 * OptionItem
 *
 * @param OptionItemProps option item props
 * @returns OptionItem contents
 */
export const OptionItem = ({ title, description, children }: OptionItemProps) => {
  return (
    <article className="option-item" css={stylesWrap}>
      <div className="option-item__text">
        <h3 className="option-item__title">{title}</h3>
        {description && <p className="option-item__description">{description}</p>}
      </div>
      <div className="option-item__option">{children}</div>
    </article>
  )
}

const stylesWrap = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'option-box'
    'text-box';
  align-items: center;
  border: 1px solid ${colors.grayBorder.hex};
  padding: ${space.md} ${space.sm};
  row-gap: ${space.xs};
  .option-item__text {
    grid-area: text-box;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: currentColor;
  }
  .option-item__title {
    font-size: ${fontSize.lg};
    text-align: center;
  }
  .option-item__description {
    margin-top: ${rem(10)};
    line-height: 2;
  }
  .option-item__option {
    grid-area: option-box;
    display: flex;
    justify-content: center;
    padding: 0 ${space.lg};
  }
  ${mq('lg')} {
    grid-template-columns: 1.715fr 1fr;
    column-gap: ${space.xl};
    grid-template-areas: 'text-box option-box';
    padding: ${space.md} ${space.lg};
    .option-item__title {
      font-size: ${fontSize.xl};
      text-align: left;
    }
  }
`
